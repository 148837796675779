<template>
    <VLayout class="rounded-md flex-column">
        <VMain>
            <div class="layout-box">
                <slot></slot>
            </div>
        </VMain>

        <VFooter class="bg-grey-lighten-2"
                 app>
            <VRow justify="center"
                  no-gutters>
                <VCol class="text-center mt-4"
                      cols="12">
                    {{ new Date().getFullYear() }} — <strong>Vuetify</strong>
                </VCol>
            </VRow>
            <div class="session-time-header"></div>
        </VFooter>
    </VLayout>
</template>

<script setup>
import { onBeforeMount, nextTick } from "vue";
import { initFunctions } from "../../init.js";

onBeforeMount(async () => {
    await nextTick()
    initFunctions();
})
</script>

<style scoped></style>
