<template>
    <v-form :name="props.searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormMultiselect fieldName="seller_id"
                                     label="Seller"
                                     size="6"
                                     showClear
                                     :options="select?.contractors"
                                     :filter="(event) => onFilter(event, 'contractors')"
                                     v-model:field="search.seller_id" />

                    <FormMultiselect fieldName="buyer_id"
                                     label="Buyer"
                                     size="6"
                                     showClear
                                     :options="select?.contractors"
                                     :filter="(event) => onFilter(event, 'contractors')"
                                     v-model:field="search.buyer_id" />

                    <FormMultiselect fieldName="exchange_id"
                                     label="Exchange"
                                     size="4"
                                     showClear
                                     :options="select?.exchanges"
                                     :filter="(event) => onFilter(event, 'exchanges')"
                                     v-model:field="search.exchange_id" />

                    <FormMultiselect fieldName="payment_id"
                                     label="Payment method"
                                     size="4"
                                     showClear
                                     :options="select?.payments"
                                     v-model:field="search.payment_id" />

                    <FormDropdown fieldName="warehouse_id"
                                  label="Warehouse"
                                  size="4"
                                  showClear
                                  :options="select?.warehouses"
                                  v-model:field="search.warehouse_id" />

                    <FormInputText fieldName="wdt_number"
                                   label="WDT number"
                                   size="4"
                                   :isFocus="true"
                                   v-model:field="search.wdt_number" />

                    <FormInputText fieldName="issue_place"
                                   label="Issue place"
                                   size="3"
                                   v-model:field="search.issue_place" />

                    <FormCalendar fieldName="issue_date_from"
                                  label="Issue date from"
                                  size="3"
                                  v-model:field="search.issue_date_from" />

                    <FormCalendar fieldName="issue_date_to"
                                  label="Issue date to"
                                  size="3"
                                  v-model:field="search.issue_date_to" />

                    <FormCalendar fieldName="sell_date_from"
                                  label="Sell date from"
                                  size="3"
                                  v-model:field="search.sell_date_from" />

                    <FormCalendar fieldName="sell_date_to"
                                  label="Sell date to"
                                  size="3"
                                  v-model:field="search.sell_date_to" />

                    <FormCalendar fieldName="payment_date_from"
                                  label="Payment date from"
                                  size="3"
                                  v-model:field="search.payment_date_from" />
                    <FormCalendar fieldName="payment_date_to"
                                  label="Payment date to"
                                  size="3"
                                  v-model:field="search.payment_date_to" />
                </div>
            </TabPanel>
        </TabView>

        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputText from '@crud/FormInputText.vue'
import FormCalendar from '@crud/FormCalendar.vue'
import FormMultiselect from '@crud/FormMultiselect.vue'
import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})

const autoSearch = ref(false)
const options = ['contractors', 'exchanges', 'payments', 'warehouses']
const select = ref(null)

let search = ref({})
prepareSearch(search, props, options, select)

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
